// AnotherComponent.js
import React, { useEffect } from 'react';
import { HotKeyManager } from "./hotkey-manager";

export const HotKey = ({shortcut, config, callback, scope}) => {

  useEffect(() => {

    const unregister = HotKeyManager.registerHotKey(
      shortcut, // 'cmd+shift+i'
      config,   // { keyCode: 73, metaKey: false, shiftKey: true, altKey: false, macCtrlKey: true },
      callback,  // () => console.log('HotKey pressed')
      scope     // 'default'
    );

    return () => {
      unregister();
    };

  }, []);

  return null;
};