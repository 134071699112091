import React from "react";
import { Button } from "@cargo/ui-kit/button/button";
import { ProcessingAnimation } from "@cargo/ui-kit/processing/processing";

import './save-cancel-buttons.scss';

export const SaveCancelButtons = ({
        form, 
        // Functions
        closeWindow, 
        saveChanges, 
        // Variables
        isSaving, 
        pendingChanges, 
        saveVisible, 
        transitionSaveOut,
        noTransition,
        saveDisabledIfNoChanges,
    }) => {

		return(
            <div className="save-area">
                <div className="save-overflow">
                    <div className="ui-group save-cancel">
                        <section className="save">
                            <div className={`save-grid save-cancel-buttons${(saveVisible || noTransition) ? ' visible' : ''}${transitionSaveOut ? ' transition-out' : ''}`}>
                                    <Button
                                        onPointerUp={(e) => {
                                            if(isSaving) return
                                        closeWindow();
                                        }}
                                        className="cancel"
                                    >
                                        <div>Cancel</div>
                                    </Button>
                                    <Button
                                        onPointerUp={(e) => {
                                            if(isSaving) return
                                            saveChanges(form, form.values);
                                        }}
                                        className={`confirm${!pendingChanges && saveDisabledIfNoChanges ? ' disabled' : ''}`}
                                    >
                                        <div>OK</div>
                                        {isSaving ? <ProcessingAnimation className="accent" /> : null }
                                    </Button>
                                </div>
                        </section>
                    </div>
                </div>
            </div>
	    )
		
}

