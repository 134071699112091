import React, { Component } from 'react';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { ProcessingAnimation } from '@cargo/ui-kit/processing/processing';
import './select.scss';

export class Select extends Component {

	constructor(props) {
		super(props);
		this.state = {
			optionText : "",
			default: "",
			selectWidth: 'auto'
		}
	
		this.selectRef = React.createRef();
	};

	setSelectText =()=> {

		if( this.props.multiple ){

			return

		} else if( this.props.useContextMenu){

			// get all nested children and look for the one with the value that matches the active one
			const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
			const activeOption = flattenedChildren.find(child=>child.props.value == this.props.field.value);
			if( activeOption){
				this.setState({
					optionText: activeOption.props['display-text']
				})
			}



		} else if ( this.selectRef.current ){
			
			if(this.props.selectedIndex){
				this.selectRef.current.selectedIndex = this.props.selectedIndex
			}

			const displayText = this.selectRef.current.options[this.selectRef.current.selectedIndex]?.getAttribute('display-text');

			const optionText = this.selectRef.current.options[this.selectRef.current.selectedIndex]?.text;

			// Always try to display alt text, fall back to option visible text
			this.setState({
				optionText: displayText ? displayText : optionText
			})		
		
		}
	};

	// 	defaultValue={{ field.defaultValue }}
	render(){

		const {field, form, children, prefix, defaultDisplay, noArrows, disabled, label, noBars, barLeft, buttonSelect, buttonLabel, className, multiple, useContextMenu, processing, dynamicWidth, onChange} = this.props;
		const selectDisplayWidth = dynamicWidth ? { width: this.state.selectWidth } : {};
		const selectEl = (
			<div disabled={disabled} className={`select${noBars ? '' : ' bars'}${className ? ' '+className : ''}${barLeft ? ' barLeft' : '' }`}>
				<label style={selectDisplayWidth}>
					{ processing ? ( <ProcessingAnimation className="dark" /> ) : ( null )}
					{!buttonSelect && !multiple ?
						<div className="select-display">
							{ prefix ? (
								<div className="select-prefix">{prefix}</div>
							) : (
								null
							)}
							<div className="select-value">
								{defaultDisplay && field.value === 'default' ? defaultDisplay : this.state.optionText}
							</div>
						</div>
					:
						<div className="select-display">
							<span>{buttonLabel}</span>	
						</div>
					}

					{multiple || useContextMenu ?

						<ContextSelectMenu
							multiple={multiple}
							field={field}
							form={form}
						>
							{children}
						</ContextSelectMenu>

					:	<select
						ref={this.selectRef}
						name={field.name}
						value={field.value}
						onBlur={field.onBlur}
						onChange={(e) => {
							form.setFieldValue(field.name, e.target.value, false);
							onChange?.(e);
							this.updateSelectWidth();
						}}
					>
						{children}
					</select>}
					{ noArrows ? (
						null
					) : (
						<div className="select-arrows">
							<div></div>
						</div>
					)}
				</label>
			</div>
		)

		if( label ){
			return(
				<div className={`label-select-group${noBars ? '' : ' bars'}${barLeft ? ' barLeft' : '' }`}>
					<div className="select-label">
						{label}
					</div>
					{selectEl}
				</div>
			)		
		}

		return selectEl

	};

	componentDidMount(){
		this.setSelectText();
		this.updateSelectWidth();
	}

	componentDidUpdate(prevProps){

		if (
			// update cover text when the value changes
			this.props.field.value != prevProps.field.value
			// Or when paginating in new content, the selected child might be added
			// after we set the initial cover text, but before the value changes.
			|| this.props.children.length !== prevProps.children.length
		){
			this.setSelectText();
			this.updateSelectWidth();
		}

	}

	updateSelectWidth() {
		if (this.props.dynamicWidth && this.selectRef.current) {
			// Retrieve the selected option and determine the display text
			const selectedOption = this.selectRef.current.options[this.selectRef.current.selectedIndex];
			const displayText = selectedOption.getAttribute('display-text') ? selectedOption.getAttribute('display-text') : selectedOption.text;
	
			// Access the parent element and query the necessary display elements
			const parentEl = this.selectRef.current.parentElement;
			if (!parentEl) return;
	
			const displayEl = parentEl.querySelector('.select-display');
			const displayTextEl = parentEl.querySelector('.select-value');
			const displayPrefixEl = parentEl.querySelector('.select-prefix');
	
			if (!displayEl || !displayTextEl || !displayPrefixEl) return;
	
			// Get computed styles for each element
			const displayStyles = window.getComputedStyle(displayEl);
			const prefixStyles = window.getComputedStyle(displayPrefixEl);
			const valueStyles = window.getComputedStyle(displayTextEl);
	
			// Create a temporary containers to mimic the display structure
			const tempContainer = document.createElement('div');
			tempContainer.style.position = 'absolute';
			tempContainer.style.visibility = 'hidden';
			tempContainer.style.whiteSpace = 'nowrap';
			tempContainer.style.fontSize = displayStyles.fontSize;
			tempContainer.style.fontFamily = displayStyles.fontFamily;
			tempContainer.style.fontWeight = displayStyles.fontWeight;
			tempContainer.style.letterSpacing = displayStyles.letterSpacing;
			tempContainer.style.padding = displayStyles.padding;
			tempContainer.style.boxSizing = displayStyles.boxSizing;
	
			const tempPrefix = document.createElement('div');
			tempPrefix.style.display = 'inline-block';
			tempPrefix.style.fontSize = prefixStyles.fontSize;
			tempPrefix.style.fontFamily = prefixStyles.fontFamily;
			tempPrefix.style.fontWeight = prefixStyles.fontWeight;
			tempPrefix.style.letterSpacing = prefixStyles.letterSpacing;
			tempPrefix.style.marginRight = '4px';
			tempPrefix.innerText = this.props.prefix || '';
	
			const tempValue = document.createElement('div');
			tempValue.style.display = 'inline-block';
			tempValue.style.fontSize = valueStyles.fontSize;
			tempValue.style.fontFamily = valueStyles.fontFamily;
			tempValue.style.fontWeight = valueStyles.fontWeight;
			tempValue.style.letterSpacing = valueStyles.letterSpacing;
			tempValue.innerText = displayText;
	
			// Append prefix and value to the temporary container
			tempContainer.appendChild(tempPrefix);
			tempContainer.appendChild(tempValue);
	
			// Append the temporary container to the body to measure its width
			document.body.appendChild(tempContainer);
			const width = tempContainer.offsetWidth + 2;
			document.body.removeChild(tempContainer);
	
			// Update the state with the new width
			this.setState({ selectWidth: `${width}px` });
		}
	}
}


class ContextSelectMenu extends Component {
	constructor(props){
		super(props);
		this.state = {
			menuOpen: false,
		}
		this.contextSelectRef = React.createRef();
	}

	render(){

		return <>
		<select
			onMouseDown={this.triggerMenu}
			ref={this.contextSelectRef}
			multiple={this.props.multiple ? '' : null}
		>
			{this.props.children}
		</select>
		</>
	}

	innerUI= (children)=>{
		return <>{children.map(child=>{
			if( child.type === 'option'){
				return <ContextMenuCheckbox
					disabled={child.props.disabled}
					key={child.props.value+'key'}
					value={ this.props.multiple ? this.props.form.values[child.props.value] : child.props.value === this.props.field.value}
					label={child.props.children}
					onPointerUp={ (e) => {
						if( this.props.multiple){
							this.props.form.setFieldValue(child.props.value, !this.props.form.values[child.props.value])
						} else {
							this.props.form.setFieldValue(this.props.field.name, child.props.value)
						}
						
					}}						
				/>						
			} else if ( Array.isArray(child) ) {
				return this.innerUI(child)
			} else {
				return child
			}

		})}</>
	}

	triggerMenu=(e)=>{
		e.preventDefault();
		const rect = this.contextSelectRef.current.getBoundingClientRect();

		const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
		const activeIndex = flattenedChildren.findIndex(child=> child.props.value == this.props.field.value);
		
		this.context.openMenu?.({
			innerUI: this.innerUI(this.props.children),
			style: {
				minWidth: rect.width+'px',
			},
			offset: {
				x: rect.x+-10, // emulate the 10px offset that the os does
				y: rect.y,
			},
			event: e,
			type: 'select',
			alignToScrollIndex: this.props.multiple && activeIndex > -1 ? false: true,
			scrollIndex: activeIndex,

			onOpen: ()=>{
				this.setState({
					menuOpen: true,
				});
			},
			onClose: ()=>{
				this.setState({
					menuOpen: false,
				});				
			}
		});

	}


	componentDidUpdate(prevProps){

		const {
			form: prevForm,
			children: prevChildren,
			...otherPrevProps
		} = prevProps;

		const {
			form,
			children,
			...otherProps
		} = this.props;

		if( this.state.menuOpen && !this.ticking){
			const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
			const prevFlattenedChildren = _.flattenDeep(Array.from(prevProps.children));

			if (
					!_.isEqual(this.props.form.values , prevProps.form.values) || 
					!_.isEqual(flattenedChildren , prevFlattenedChildren )

			){
				this.ticking = true;
				this.context.updateInnerUI(this.innerUI(this.props.children));
				requestAnimationFrame(()=>{
					this.ticking = false;
				})
			}

		}




	}
}
ContextSelectMenu.contextType = MenuContext;